.faq-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-in-out;
  }
  
  .card-header {
    cursor: pointer;
    background: #f8f9fa;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .card-header:hover {
    background: #e9ecef;
  }
  
  .faq-title {
    margin: 0;
    font-size: 20px;
  }
  
  .carda {
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  