#main{
  margin: 0px;
  padding: 0px;
}
*{
  margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
  font-family: Poppins, sans-serif;
    box-sizing: border-box;
}





#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}





.purple-circle{
  width: 273px;
  height: 272px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.4) 0%, #C6C8E7 100%);
  border-radius: 100%;
  box-shadow: 1px 1px 6.8px 0px rgba(0, 0, 0, 0.25);
}
.pink-circle{
  width: 207px;
  height: 216px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.4) 0%, #F9D0D1 100%);
  box-shadow: 1px 1px 6.8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
}



.signup-circle1{
  margin-top: 112px;
  margin-left: 255px;
  position: relative;
}
.signup-circle2{
  margin-top: 146px;
  margin-left: 761px;
  position: relative;
}



.call-cards{
  overflow-y: auto;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--GLASS, rgba(255, 255, 255, 0.41));
  backdrop-filter: blur(50px);
  box-shadow: 0px 2px 3.8px 0px rgba(105, 105, 105, 0.31);
  border-radius: 28.46px;
}










.join-room {
  max-width: 380px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  padding: 15px;
}
.join-room .logo {
  width: 70px;
}
.join-room input {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #fff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  margin-top: 15px;
}
.join-room .join-channel {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  font-size: 14px;
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  outline: none;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  line-height: 1.5714285714;
  box-sizing: border-box;
  overflow: visible;
  margin-top: 10px;
}
.join-room .join-channel.disabled {
  background-color: #d9d9d9;
  box-shadow: none;
}
.room {
  display: 'flex';
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  
  
  /* overflow: hidden; */
  
  
  
  background: #303438;
}
.user-list {
 display: flex;
 justify-content: end;
}
.localuser {
  border-style: solid;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  width: 90vw;
  height: 75vh;
  position: absolute;
}
.remoteuser {
  border-style: solid;
  --un-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--un-border-opacity));
  border-width: 1px;
  box-sizing: border-box;
  width: 20vw;
  height: 20vh;
  position: relative;
  margin: 20px;
}
.user-name {
  --un-text-opacity: 1;
  color: rgba(255, 255, 255, var(--un-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  --un-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--un-bg-opacity));
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
  display: inline-flex;
  z-index: 2;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
}
.control {
  --un-text-opacity: 1;
  color: rgba(209, 213, 219, var(--un-text-opacity));
  padding-top: 0.0rem;
  padding-bottom: 0.0rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --un-bg-opacity: 1;
  background-color: rgba(33, 36, 44, var(--un-bg-opacity));
  grid-gap: 0.75rem;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
  box-sizing: border-box;
}
.left-control {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  flex: 1 1 0%;
  display: flex;
  height: 100%;
}
.i-microphone {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5h2Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2rem;
}
.i-microphone.off {
  opacity: 0.2;
}
.i-camera {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1.2em' height='1.2em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M4 4h3l2-2h6l2 2h3a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2m8 3a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5m0 2a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2rem;
}
.i-camera.off {
  opacity: 0.2;
}
.btn {
  display: inline-flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  align-items: center;
  font-size: 0;
  color: inherit;
}
.btn-phone {
  border-radius: 0.55rem;
  padding: 0.5rem 4rem;
  color: #fff;
  background-color: #16a34a;
}
.btn-phone-active {
  background-color: #dc2626;
}
.btn .btn-phone {
  border-radius: 0.55rem;
  padding: 0.5rem 4rem;
  color: #fff;
  background-color: #16a34a;
}
.btn .btn-phone-active {
  background-color: #dc2626;
}
.i-phone-hangup {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .4-.23.74-.56.9c-.98.49-1.87 1.12-2.67 1.85c-.17.18-.42.29-.67.29c-.3 0-.55-.12-.73-.3L.29 13.08a.99.99 0 0 1-.29-.7c0-.28.11-.53.29-.71C3.34 8.77 7.46 7 12 7c4.54 0 8.66 1.77 11.71 4.67c.18.18.29.43.29.71c0 .27-.11.52-.29.7l-2.48 2.48c-.18.18-.43.3-.73.3a.98.98 0 0 1-.68-.29c-.79-.73-1.68-1.36-2.66-1.85a.996.996 0 0 1-.56-.9v-3.1C15.15 9.25 13.6 9 12 9Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
}
.i-phone {
  --un-icon: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 24 24' width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fill='currentColor' d='M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25c1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2Z'/%3E%3C/svg%3E");
  -webkit-mask: var(--un-icon) no-repeat;
  mask: var(--un-icon) no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  background-color: currentColor;
  color: inherit;
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
}






















/* Background Circles */
.background-circles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.background-circles::before,
.background-circles::after {
  content: "";
  position: absolute;
  width: 300px;  /* Adjust size as needed */
  height: 300px;
  border-radius: 50%;
  opacity: 1;
  
  z-index: -1;
}

/* Pink Circle */
.background-circles::before {
  background:radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.40) 0%, #F9D0D1 100%);
  box-shadow: 1px 1px 6.8px 0px rgba(0, 0, 0, 0.25);
  /* Light Pink */
  top: 15%;
  left: -2%;
}

/* Purple Circle */
.background-circles::after {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.40) 0%, #C6C8E7 100%);

/* BALL SHADOW */
  box-shadow: 1px 1px 6.8px 0px rgba(0, 0, 0, 0.25);
  bottom: 0%;
  right: -15%;
}
.background-circles::before {
  background:radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.40) 0%, #F9D0D1 100%);
  box-shadow: 1px 1px 6.8px 0px rgba(0, 0, 0, 0.25);
  /* Light Pink */
  left: -2%;
}

/* Purple Circle */
.background-circles::after {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.40) 0%, #C6C8E7 100%);

/* BALL SHADOW */
  box-shadow: 1px 1px 6.8px 0px rgba(0, 0, 0, 0.25);
  
  right: -5%;
}


/* Additional random circles for better effect */





@media (max-width: 768px) {
  .background-circles::before, 
  .background-circles::after,
  .circle {
      width: 150px;
      height: 150px;
      
  }
}





.toggle-switch {
  width: 50px;
  height: 25px;
  background: #ccc;
  border-radius: 25px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s;
}

.toggle-switch.on {
  background: #4caf50;
}

.switch-handle {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  transition: left 0.3s;
}

.toggle-switch.on .switch-handle {
  left: 27px;
}
