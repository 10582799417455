.back-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    
    color: white;
    border: none;
    border-radius: 5px;
    padding: 6px 12px;
    cursor: pointer;
    
    /* opacity: 0.7; */
    display: none; 
    font-size: 25px;
   
    z-index: 2;
    background: transparent;
}

.back-to-top:hover {
    text-decoration:none;
    opacity: 1;
    
}
@media (max-width:425px) {
    .back-to-top{
        width: 15%;
        right: 2%;
        font-weight: 800;
        font-size: 24px;
    }
}
.show {
    display: block; 
}