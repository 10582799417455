.signup{
    overflow-y: auto;
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, -50%);
    
    background: var(--GLASS, rgba(255, 255, 255, 0.41));
    backdrop-filter: blur(50px);
    box-shadow: 0px 2px 3.8px 0px rgba(105, 105, 105, 0.31);
    border-radius: 28.46px;
    margin-top: 158px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
    
  
  }